$(document).ready(function (){
  $('#horizontal-navbar .navbar-mobile #search-icon').on('click', function(){
    $(this).css('display', 'none');
    $('#horizontal-navbar .navbar-mobile .close-icon, #horizontal-navbar .navbar-mobile .fourth_mobile_block').css('display', 'flex');
  });

  $('#horizontal-navbar .navbar-mobile .close-icon').on('click', function(){
    $('#horizontal-navbar .navbar-mobile .close-icon, #horizontal-navbar .navbar-mobile .fourth_mobile_block').css('display', 'none');
    $('#horizontal-navbar .navbar-mobile #search-icon').css('display', 'flex');
  });


  $('#horizontal-navbar .navbar-desktop #search-icon').hover(
    function() {
      $( this ).find("circle, line").attr("stroke", "#FF7700");
    }, function() {
      $( this ).find("circle, line").attr("stroke", "#9a9b9e");
    }
  );

  $(document).on('keypress', function (event) {
    const target = $(event.target);

    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      if (target.prop('id') == 'searchPC') {
        if (target.val() == '') {
          event.preventDefault();
        }
      } else {
        return;
      }
    }
    if (target.is('input') || target.is('textarea')) {
      return;
    }

    $("#searchPC").focus();
  });
});
